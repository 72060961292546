<template>
  <div id="checkinActive" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <v-card id="dialog" class="TF type2" width="100%">
        <div class="b-content">
          <div class="B-dialogform step">
            <div class="t-namepage" style="text-align: center; font-size: 18px;">
              <b>Alcohol & Urine Controlled Drugs Test Consent and Policy Compliance Declaration Form
              <br />(This form is used for Alcohol and Urine Drugs Test program only)</b>
              <br />แบบสำแดงความยินยอมตรวจ และแบบสำแดงยืนยันปฏิบัติตามนโยบายด้านแอลกอฮอล์และสารเสพติด
            </div>
            <div class="b-form">
              <v-stepper v-model="stepdialog">
                <v-stepper-header>
                  <v-stepper-step :complete="stepdialog > 1" step="1"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepdialog > 2" step="2"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepdialog > 3" step="3"></v-stepper-step>
                </v-stepper-header>

                <v-stepper-items class="T-size-16">
                  <!-- Start step 1 -->
                  <v-stepper-content step="1" ref="focusStep1">
                    <div class="b-contentstep" style="color: white">

                      <!-- Section I -->
                      <div class="box-S4">
                        <div class="B-passenger">
                          <p>
                            <u><b>Section I<br />ส่วนที่ 1</b></u>
                          </p>
                          <div class="b-person">
                            <div class="b-display">
                              <div class="B-display"></div>
                            </div>
                            <div class="b-name-company">
                              <p class="t-name T-size-14">
                                {{ u_firstname }} {{ u_lastname }}
                              </p>
                              <div class="t-company">
                                Position (ตำแหน่ง) : -
                              </div>
                              <div class="t-company">
                                Company (บริษัท) : {{ c_name }}
                              </div>
                              <!-- <div class="t-company">
                                Supervisor’s name (ชื่อหัวหน้างาน) : -
                              </div>
                              <div class="t-company">Department (แผนก) : -</div> -->
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="line"></div>
                      <div class="box-S4 noPadding">
                        <div class="B-passenger">
                          <p><b>Test (ชนิดการตรวจ)</b></p>
                        </div>
                      </div>
                      <v-radio-group v-model="maf_test" v-on:change="CheckStep1()" disabled>
                        <v-radio label="Breath Alcohol แอลกอฮอล์ทางลมหายใจ" value="1"></v-radio>
                        <v-radio label="Urine Controlled Substance สารเสพติดทางปัสสาวะ" value="2"></v-radio>
                      </v-radio-group>
                      <p><b>Reasons (สาเหตุการตรวจ)</b></p>
                      <v-radio-group v-model="maf_reason" v-on:change="CheckStep1()" disabled>
                        <v-radio label="Pre-employment ก่อนเข้าทำงาน" value="1"></v-radio>
                        <v-radio label="With cause มีสาเหตุ" value="2"></v-radio>
                        <v-radio label="Random test การสุ่มตรวจ" value="3"></v-radio>
                        <v-radio label="Other อื่นๆ" value="4"></v-radio>
                      </v-radio-group>
                      <div v-show="maf_reason == 4">
                        Other อื่นๆ โปรดระบุ
                        <v-text-field class="hideMessage" label="โปรดระบุ" v-model="maf_reason_remark"
                          @input="CheckStep1()"></v-text-field>
                      </div>
                      <!-- Section I -->

                      <!-- Section II -->
                      <div class="box-S4">
                        <div class="B-passenger">
                          <p>
                            <u><b>Section II<br />ส่วนที่ 2</b></u>
                          </p>
                          <v-radio-group v-model="maf_consent_permiss" v-on:change="CheckStep1()">
                            <v-radio
                              label="I, the undersigned give my permission for the company to conduct the test. ข้าพเจ้ายินยอมให้บริษัททำการตรวจครั้งนี้"
                              value="true"></v-radio>
                            <br />
                            <v-radio
                              label="I, the undersigned decline my permission for the company to conduct the test. ข้าพเจ้าไม่ยินยอมให้บริษัททำการตรวจครั้งนี้"
                              value="false"></v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                      <div>
                        <p style="padding-left: 30px">
                          <b>
                            I understand that my declination may result in
                            disciplinary action which includes termination of
                            employment.</b>
                          <span>
                            ข้าพเจ้าเข้าใจและยอมรับว่าการปฎิเสธการตรวจอาจมีผลต่อการลงโทษและรวมถึงการสิ้นสุดการว่าจ้างโดยบริษัท
                          </span>
                        </p>
                      </div>
                      <!-- Section II -->

                      <!-- Section III -->
                      <div class="box-S4">
                        <div class="B-passenger">
                          <p>
                            <u><b>Section III Self – declaration of any
                                medications or drugs used (within 14 days)<br />ส่วนที่
                                3 การยินยอมแจ้งการใช้ยา หรือ สารเสพติดด้วยตนเอง
                                (ภายใน 14 วัน)</b></u>
                          </p>
                          <v-radio-group v-model="maf_consent_drug" v-on:change="CheckStep1()">
                            <v-radio
                              label="I, the undersigned declare that I do not use any medication or drugs ข้าพเจ้า ขอแจ้งว่า ไม่ได้ใช้ยา หริอสารเสพติดใดๆ "
                              value="false"></v-radio>
                            <br />
                            <v-radio
                              label="I, the undersigned declare that I use the medication or drugs as listed below ข้าพเจ้า ขอแจ้งว่า ใช้ยาหรือสารเสพติดดังต่อไปนี้"
                              value="true"></v-radio>
                          </v-radio-group>
                        </div>
                      </div>

                      <div v-show="maf_consent_drug == 'true'">
                        <p></p>
                        <div class="box-S4">
                          <div class="N-Page">Drugs/Dosage รายการยา</div>
                        </div>
                        <div class="b-LegLog box-S4 B-carddetail" v-for="(item, indexDrugsDosage) in arrDrugsDosage"
                          :key="item.p_id">
                          <div class="b-nameLocation flex-between-center">
                            <div class="box-S2 noPadding">
                              <div class="b-neme">
                                No {{ indexDrugsDosage + 1 }}
                              </div>
                            </div>
                            <div class="b-btn">
                              <v-btn class="theme-btn-even btn-delete" @click="
                                DeleteDrugsDosage(item, indexDrugsDosage)
                              " style="width: auto">
                                <span class="I-bin"></span>
                                <span></span>
                              </v-btn>
                            </div>
                          </div>
                          <div class="b-inputData box-S4 noPadding">
                            <div class="box-S4 flex-between noPadding">
                              <div class="box-S2 noPadding">
                                <v-combobox class="hideMessage" :items="itemsListDrugs" v-model="item.mafd_drugs_name"
                                  @input="CheckStep1()" label="Drugs ยา" filled></v-combobox>
                              </div>
                              <div class="box-S1 noPadding">
                                <v-text-field class="hideMessage" type="number" v-model="item.mafd_dosage"
                                  @input="CheckStep1()" label="Dosage ขนาดที่ใช้"></v-text-field>
                              </div>
                              <div class="box-S1 noPadding">
                                <v-select :items="itemsListUnit" item-text="un_name" item-value="un_id"
                                  label="Unit หน่วย" v-model="item.mafd_unit" return-object></v-select>
                              </div>
                            </div>
                            <div class="box-S4 flex-between noPadding">
                              <div class="box-S2 noPadding">
                                <v-text-field class="hideMessage" v-model="item.mafd_prescribed_by"
                                  @input="CheckStep1()" label="Prescribed by สั่งจ่ายโดย"></v-text-field>
                              </div>
                              <div class="box-S2 noPadding">
                                <v-menu v-model="item.menuselect_mafd_date" :close-on-content-click="false"
                                  transition="scale-transition" offset-y>
                                  <template v-slot:activator="{ on }">
                                    <v-text-field class="hideMessage input-date" v-model="item.mafd_date"
                                      label="when เมื่อ" readonly v-on="on" append-icon="mdi-calendar-range">
                                    </v-text-field>
                                  </template>
                                  <v-date-picker v-model="item.mafd_date" @input="item.menuselect_mafd_date = false">
                                  </v-date-picker>
                                </v-menu>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="box-S4">
                          <v-btn class="theme-btn-even MGB10 w100" @click="AddDrugsDosage()">
                            <span class="I-create"></span>
                            <span>Add Drugs/Dosage (เพิ่มยา/ขนาดที่ใช้)</span>
                          </v-btn>
                        </div>
                      </div>
                      <!-- Section III -->

                    </div>

                    <div class="line"></div>
                    <div class="box-S4">
                      <div class="b-btnSave">
                        <v-btn class="theme-btn-even btn-save Bsize100" v-show="step1" @click="GotoStepDialog2()">
                          <span class="T-size-18">NEXT</span>
                        </v-btn>
                      </div>
                    </div>
                  </v-stepper-content>
                  <!-- End step 1 -->

                  <!-- Start step 2 -->
                  <v-stepper-content step="2" ref="focusStep2">
                    <div class="b-contentstep" style="color: white">
                      <!-- Section IV -->
                      <div class="box-S4">
                        <div class="B-passenger">
                          <p>
                            <u><b>Section IV Drugs & Alcohol Policy Compliance Declaration
                              <br />ส่วนที่ 4 แบบสำแดงยืนยันปฏิบัติตามนโยบายด้านสิ่งเสพติดและแอลกอฮอล์</b></u>
                          </p>
                        </div>
                      </div>

                      <div class="box-S4">
                        <p style="text-align: center;"><b>สาร/ยาหรือสิ่งเสพติดต้องห้าม<br/> Prohibited drugs / substances/ Medicine</b></p>
                        <p>
                          1) ท่านมีสารหรือสิ่งเสพติดต้องห้ามดังต่อไปนี้ในสัมภาระหรือพกพา ที่ต้องสำแดงหรือไม่?
                          <br/>Do you have any of the following substances in your possession or in baggage to declare? (ทำเครื่องหมาย Mark √)
                        </p>
                      </div>

                      <div class="box-S4">
                        1. กัญชา, กัญชง กระท่อม<br/>Marijuana (Cannabis, Hemp) Kratom,
                      </div>
                      <div class="box-S4 noPadding b-neme">
                        <v-radio-group v-model="maf_declare_1" v-on:change="CheckStep2()">
                          <v-radio label="มี Yes" value="true"></v-radio>
                          <v-radio label="ไม่มี No" value="false"></v-radio>
                        </v-radio-group>
                      </div>

                      <div class="box-S4">
                        2. โคเคน ฝิ่น เฮโรอีน<br/>Cocaine, Opiates, Heroine,
                      </div>
                      <div class="box-S4 noPadding b-neme">
                        <v-radio-group v-model="maf_declare_2" v-on:change="CheckStep2()">
                          <v-radio label="มี Yes" value="true"></v-radio>
                          <v-radio label="ไม่มี No" value="false"></v-radio>
                        </v-radio-group>
                      </div>
                      
                      <div class="box-S4">
                        3. มอร์ฟีน ยาบ้า<br/>Morphine, Amphetamine,
                      </div>
                      <div class="box-S4 noPadding b-neme">
                        <v-radio-group v-model="maf_declare_3" v-on:change="CheckStep2()">
                          <v-radio label="มี Yes" value="true"></v-radio>
                          <v-radio label="ไม่มี No" value="false"></v-radio>
                        </v-radio-group>
                      </div>
                  
                      <div class="box-S4">
                        4. ยาอี ยาไอซ์<br/>Ecstasy, Methylenedioxyamphetamine 
                      </div>
                      <div class="box-S4 noPadding b-neme">
                        <v-radio-group v-model="maf_declare_4" v-on:change="CheckStep2()">
                          <v-radio label="มี Yes" value="true"></v-radio>
                          <v-radio label="ไม่มี No" value="false"></v-radio>
                        </v-radio-group>
                      </div>

                      <div class="box-S4">
                        5. ยาที่มีส่วนผสมของกัญชา ฝิ่น<br/>Medicine with marijuana/opiates as an ingredient
                      </div>
                      <div class="box-S4 noPadding b-neme">
                        <v-radio-group v-model="maf_declare_5" v-on:change="CheckStep2()">
                          <v-radio label="มี Yes" value="true"></v-radio>
                          <v-radio label="ไม่มี No" value="false"></v-radio>
                        </v-radio-group>
                      </div>

                      <p><b>** หากท่านมีคำถามหรือข้อสงสัยเกี่ยวกับสิ่งที่พกมาด้วย เช่น ยารักษาโรคหรืออาหารเสริม กรุณาสำแดงและสอบถามเจ้าหน้าที่
                        <br/>If you have any medicines or food supplements on you and in doubts, please declare and contact check-in staff.</b></p>
                      
                      <div class="box-S4">
                        <p>
                          2) ท่านได้นำของเหลวหรือเครื่องดื่มดังต่อไปนี้ติดในสัมภาระหรือพกพาที่ต้องสำแดงหรือไม่
                          <br/>Do you have any of the following alcohol beverages on you or in baggage to declare? (ทำครื่องหมาย Mark √)
                        </p>
                      </div>

                      <div class="box-S4">
                        1. วิสกี้, ไวน์, บรั่นดี, รัม<br/>Whiskey, wine, brandy, rum,
                      </div>
                      <div class="box-S4 noPadding b-neme">
                        <v-radio-group v-model="maf_declare_6" v-on:change="CheckStep2()">
                          <v-radio label="มี Yes" value="true"></v-radio>
                          <v-radio label="ไม่มี No" value="false"></v-radio>
                        </v-radio-group>
                      </div>

                      <div class="box-S4">
                        2. สาโท, อุ, กะแช่<br/>Malt, booze, liquor
                      </div>
                      <div class="box-S4 noPadding b-neme">
                        <v-radio-group v-model="maf_declare_7" v-on:change="CheckStep2()">
                          <v-radio label="มี Yes" value="true"></v-radio>
                          <v-radio label="ไม่มี No" value="false"></v-radio>
                        </v-radio-group>
                      </div>

                      <div class="box-S4">
                        3. เบียร์, เบียร์ 0% แอลกอฮอล์, สาเก<br/>Beers, 0% alcohol beer, sake
                      </div>
                      <div class="box-S4 noPadding b-neme">
                        <v-radio-group v-model="maf_declare_8" v-on:change="CheckStep2()">
                          <v-radio label="มี Yes" value="true"></v-radio>
                          <v-radio label="ไม่มี No" value="false"></v-radio>
                        </v-radio-group>
                      </div>

                      <div class="box-S4">
                        4. ไวน์คูลเลอร์, เครื่องดื่มหมักยีสต์ทุกประเภท<br/>Wine Cooler, any alcohol content beverages
                      </div>
                      <div class="box-S4 noPadding b-neme">
                        <v-radio-group v-model="maf_declare_9" v-on:change="CheckStep2()">
                          <v-radio label="มี Yes" value="true"></v-radio>
                          <v-radio label="ไม่มี No" value="false"></v-radio>
                        </v-radio-group>
                      </div>

                      <div class="box-S4">
                        5. ยาแก้ไอ, ยาแก้ท้องอืด<br/>Cough medicine. Carminative mixture
                      </div>
                      <div class="box-S4 noPadding b-neme">
                        <v-radio-group v-model="maf_declare_10" v-on:change="CheckStep2()">
                          <v-radio label="มี Yes" value="true"></v-radio>
                          <v-radio label="ไม่มี No" value="false"></v-radio>
                        </v-radio-group>
                      </div>

                      <p><b>** หากท่านมีคำถามหรือข้อสงสัยเกี่ยวกับสิ่งที่พกมาด้วย เช่น ยารักษาโรคหรืออาหารเสริม กรุณาสำแดงและสอบถามเจ้าหน้าที่<br/>
                      If you have any medicines or food supplements on you and in doubts, please declare and contact check-in staff.</b></p>
                      <!-- Section IV -->
                    </div>

                    <div class="line"></div>
                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="BackStepDialog(1)">
                        <span class="T-size-18">BACK</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-save Bsize100" text v-show="step2" @click="GotoStepDialog3()">
                        <span class="T-size-18">NEXT</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <!-- End step 2-->

                  <!-- Start step 3 -->
                  <v-stepper-content step="3" ref="focusStep3">
                    <div class="b-contentstep" style="color: white">
                      <div class="box-S4 b-neme T-size-18">
                        <div class="box-s4">
                          <v-checkbox v-model="consented"
                            :label="`ข้าพเจ้ายินดีปฏิบัติตามนโยบายของบริษัท และขอยืนยันว่าข้อมูลที่สำแดงตามแบบสอบถามข้างบนเป็นจริงทุกประการ I acknowledge the Company’s Drugs and Alcohol Policy and hereby declare and confirm all answers are correct and true.`">
                          </v-checkbox>
                        </div>
                      </div>
                    </div>

                    <div class="line"></div>
                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="BackStepDialog(2)">
                        <span class="T-size-18">BACK</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-save Bsize100" text v-show="consented"
                        @click="dialogConfirm = true">
                        <span class="T-size-18">CONFIRM</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <!-- End step 3-->
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
        </div>
      </v-card>

      <v-dialog v-model="dialogConfirm" persistent max-width="300">
        <v-card id="dialog" class="TF type1">
          <div class="b-content">
            <div class="B-dialog save">
              <div class="t-title">Confirm Information</div>
              <div class="t-des"></div>
              <div class="box-S4"></div>
            </div>
            <div class="box-btn">
              <v-btn @click="dialogConfirm = false" class="theme-btn-even btn-cancel Bsize100" text>
                <span class="T-size-18">NO</span>
              </v-btn>
              <v-btn @click="SaveToDB()" class="theme-btn-even btn-save Bsize100" text>
                <span class="T-size-18">YES</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogSaveComplete" persistent max-width="300">
        <v-card id="dialog" class="TF type1">
          <div class="b-content">
            <div class="B-dialog confirm">
              <div class="t-title">Save Completed</div>
            </div>
            <div class="box-btn">
              <v-btn class="theme-btn-even btn-ok Bsize100" style="width: 100%;" text :to="{
                name: 'MubadalaForCustomerEFormSignature',
                params: { id: this.$route.params.id },
              }">
                <span class="T-size-18">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { format } from "date-fns";

export default {
  data: () => ({
    stepdialog: 1,
    u_firstname: "",
    u_lastname: "",
    c_name: "",
    o_name: "",

    //Step I Start
    step1: false,
    maf_test: '1',
    maf_reason: '1',
    maf_reason_remark: "",
    //End I Start

    //Step II Start
    step2: false,
    maf_consent_permiss: null,
    //End II Start

    //section III
    step3: false,
    maf_consent_drug: null,
    itemsListDrugs: ["0.1%TAORALPAST1GM",
      "3TC+AZT",
      "absolutealcohol95%",
      "ACETARSOLUTION1000ML",
      "ACETLCYSTEINE",
      "acetylcysteine",
      "aciclovir",
      "ACTIVATEDCHARCOAL(50GM)",
      "ACYCLOVIR200MGTAB",
      "adenosine",
      "ADRENALINE",
      "ALBENDAZOLE",
      "alfuzosin hydrochloride",
      "ALLOPURINOL",
      "ALUMMILK",
      "AMILORIDE5MG+HCTZ",
      "aminophylline",
      "AMIODARONE",
      "amiodarone hydrochloride",
      "amitriptyline",
      "AMLODIPINE",
      "ammonium carbonate + camphorated opium tincture + senega tincture + squill tincture",
      "amoxicillin",
      "AMOXYCILLIN",
      "amoxycillin 500mg",
      "Amoxycillin875mg+Clavulanicacid125mg",
      "ampicillin",
      "ANNA R-DENlevonorgestrel+ethonylestradiol",
      "AROMATICAMMOMIASPIRIT450ML",
      "ascorbic acid",
      "aspirin",
      "ATENOLOL",
      "ATROPINESULFATE0.6MGIN1MLINJ.1146.",
      "azithromycin",
      "BACTRIMTAB",
      "BCGVACCINEINJ3183.",
      "benserazide + levodopa",
      "benzathine benzylpenicillin",
      "benzoic acid + salicylic acid",
      "benzyl benzoate",
      "BENZYLBENZOATE",
      "BENZYLBENZOATEEMULSION1151.",
      "BeradualNB",
      "Beradualsolution20ml",
      "BETAHISTINEMESYLATE",
      "BISACODYL",
      "bromhexine hydrochloride",
      "BROWNMIXT",
      "budesonide",
      "budesonide + formoterol fumarate dihydrate",
      "CALAMINE",
      "calamine + zinc oxide",
      "calciferol",
      "CALCIUM",
      "calcium chloride + potassium chloride + sodium chloride + sodium lactate",
      "calciumpolystyrenesulfonate",
      "CARBAMAZEPINE",
      "CARMINATIVEMIXT",
      "carvedilol",
      "cefalexin",
      "CEFAZOLININJ.",
      "cefoperazone + sulbactam",
      "CEFTRIAXONE1GMINJ",
      "CEPHALEXIN",
      "CETIRIZINE",
      "CHLOPHENIRAMINE",
      "CHLOPROMAZINE",
      "CHLORAMPHENICOLEARDROP1%",
      "CHLORAMPHENICOLEYEDROP0.5%1175.",
      "CHLORAMPHENICOLEYEOINTMENT",
      "chlorhexidine gluconate",
      "CHLOROQUIN",
      "chlorphenamine maleate",
      "chlorpromazine",
      "Clindamycininj.",
      "clonazepam",
      "CLOPIDOGREL BISULFATE USP75MG",
      "Clorazepatedip.",
      "clotrimazole",
      "CLOTRIMAZOLE1%CREAM(5GM.)",
      "CLOTRIMAZOLE100MGVAG",
      "CLOXACILLIN1GMINJ1189.",
      "CLOXACILLINORALSUSP.",
      "COALTARSHAMPOO",
      "COBRAANTIVENOMINJ",
      "colchicine",
      "COLCHICINE0.6MG",
      "ConjugatedOestrogen",
      "CO-TRIMOXAZOLE SUSPENSION 60 ml",
      "crude plasma protein with king cobra antivenin",
      "crude plasma protein with malayan pit viper antivenin",
      "D-5-S/21000ML1202.",
      "D-5-S/3500ML1204.",
      "D-5-S/4500ML1205.",
      "D-5-S/5500ML",
      "D-5-S1000ML1201.",
      "D-5-W1000ML",
      "D-5-W100ML",
      "D-5-W500ML",
      "dexamethasone",
      "DEXAMETHASONE4MGIN1MLINJ.1209.",
      "dextran + sodium chloride",
      "DEXTRAN401210.",
      "DEXTROMETROPHAN",
      "Diazepam",
      "DIAZEPAM10MGIN2MLINJ.",
      "dibasic sodium phosphate + monobasic sodium phosphate",
      "Diclofenac",
      "Dicloxacillin",
      "diethylcarbamazine citrate",
      "Digoxin",
      "Dimenhydrinate",
      "diphtheria toxoid + hepatitis B + pertussis toxoid, whole cell + tetanus toxoid",
      "diphtheria toxoid + hepatitis B virus vaccine + pertussis, inactived + tetanus toxoid",
      "diphtheria toxoid + pertussis toxoid, inactivated bordetella + tetanus toxoid",
      "diphtheria toxoid + pertussis vaccine, acellular + tetanus toxoid",
      "Diptheria-Pertussive-Tetanus",
      "DIPTHERIA-TETANUSVACCINE",
      "DMPAINJ2403.",
      "DMPAINJ2404.",
      "Domperidone",
      "Dopamine",
      "Doxazosin mesylate",
      "Doxycycline",
      "drotaverine",
      "dT vaccine (booster)",
      "dT vaccine (dose2)",
      "dT vaccine (dose3)",
      "DTP-HBw",
      "efavirenz",
      "efavirenz + emtricitabine + tenofovir disoproxil fumarate",
      "enalapril",
      "ENOXAPARINSODIUM",
      "EPINEPHRINE1:1000",
      "Ergotamine",
      "ERIC1000U/5ML",
      "Erythromycin",
      "Ethambutol",
      "ethyl alcohol",
      "Etonogestrel implant",
      "FBC",
      "FENOFIBRATE",
      "fenoterol + ipratropium bromide",
      "fenoterol hydrobromide + ipratropium bromide",
      "ferrous fumarate + folic acid + potassium iodide",
      "ferrous sulfate + thiamine",
      "Ferrous+Iodine+Foic acid",
      "ferrous+iodine+folic",
      "Ferrousfumarate",
      "Fluconazole",
      "FluoxetineHCl",
      "FLUPHENAZINE50MGIN2ML.INJ.",
      "fluticasone + salmeterol",
      "fluticasone propionate + salmeterol",
      "Folicacid",
      "FUROSEMIDE",
      "gabapentin",
      "GEMFIBROZIL",
      "gentamicin",
      "GENTAMICIN80MGIN2MLINJ.",
      "Glibenclamide",
      "gliclazide",
      "Glipizide",
      "Glucose",
      "glucose + sodium chloride",
      "Glycerylguaiaculate(ࡧ鹡ʧ)",
      "GPO ALCOHOL",
      "GPO ALCOHOL 450ML",
      "GPO-VIRS30",
      "GPO-Z250MG",
      "Griseofulvin",
      "Guaifenecin",
      "Haloperidol",
      "haloperidol decanoate",
      "HCTZ25MG",
      "HepatitisBvaccine",
      "HEPATITISVACCINE1265.",
      "HEPATITISVACCINEINJ",
      "HEPATITISVACCINEINJ.",
      "HISTA-OPH EYE DROP1266.",
      "HRIG300UIN2MLINJ.",
      "HUMULIN70:30",
      "hydralazine hydrochloride",
      "HydralazineHCL",
      "Hydrochlorothiazide",
      "hydrocortisone",
      "hydrogen peroxide",
      "Hydroxycine",
      "HYOSCINE",
      "hyoscine butylbromide",
      "Ibuprofen",
      "imipramine hydrochloride",
      "INDINAVIR",
      "influenzavaccine",
      "Insulin",
      "insulin human",
      "insulin human + isophane insulin",
      "INSULINMIXTARD30PENFILL",
      "iodine",
      "Isoniacid"],
    arrDrugsDosage: [],
    // itemsListDosage: [
    //   {
    //     ds_id: 1,
    //     ds_name: "1",
    //   },
    //   {
    //     ds_id: 2,
    //     ds_name: "2",
    //   },
    //   {
    //     ds_id: 3,
    //     ds_name: "3",
    //   },
    //   {
    //     ds_id: 4,
    //     ds_name: "4",
    //   },
    //   {
    //     ds_id: 5,
    //     ds_name: "5",
    //   },
    //   {
    //     ds_id: 6,
    //     ds_name: "6",
    //   },
    //   {
    //     ds_id: 7,
    //     ds_name: "7",
    //   },
    //   {
    //     ds_id: 8,
    //     ds_name: "8",
    //   },
    //   {
    //     ds_id: 9,
    //     ds_name: "9",
    //   },
    //   {
    //     ds_id: 10,
    //     ds_name: "10",
    //   },
    //   {
    //     ds_id: 11,
    //     ds_name: "More than",
    //   },
    // ],
    itemsListUnit: [
      {
        un_id: 1,
        un_name: "มิลลิกรัม",
      },
      {
        un_id: 2,
        un_name: "ช้อนโต๊ะ",
      },
      {
        un_id: 3,
        un_name: "ช้อนชา",
      },
    ],

    //section IV
    maf_declare_1:null,
    maf_declare_2:null,
    maf_declare_3:null,
    maf_declare_4:null,
    maf_declare_5:null,
    maf_declare_6:null,
    maf_declare_7:null,
    maf_declare_8:null,
    maf_declare_9:null,
    maf_declare_10:null,
    //section IV

    consented: false,
    dialogConfirm: false,
    dialogSaveComplete: false,
  }),

  components: {},
  async mounted() {
    let q = {};
    q.p_id = this.$route.params.id;
    let res = await feathersClientUOA
      .service("viewcheckinpassenger-2")
      .find({ query: q });
    let user = res.data[0];
    if (user.maf_status != 'No Data') {
       if (user.mcf_pass_signature == null || user.mhf_pass_singnature == null || user.maf_pass_signature == null) {
        this.$router.push({
          path: `/MubadalaForCustomerEFormSignature/${this.$route.params.id}`,
        });
      }
      else
      {
        this.$router.push({
          path: `/mubadalaforcustomereformthankyou`,
        });
      }
    }
    this.u_firstname = user.u_firstname;
    this.u_lastname = user.u_lastname;
    this.c_name = user.c_name;
  },
  methods: {
    AddDrugsDosage() {
      var datainsert = {};
      datainsert["mafd_drugs_name"] = "";
      datainsert["mafd_dosage"] = "";
      datainsert["mafd_unit"] = this.itemsListUnit[0];
      datainsert["mafd_prescribed_by"] = "";
      datainsert["mafd_date"] = format(new Date(), "yyyy-MM-dd");
      datainsert["menuselect_mafd_date"] = false;

      this.arrDrugsDosage.push(datainsert);
      this.CheckStep1();
    },

    DeleteDrugsDosage(itemPass) {
      const index = this.arrDrugsDosage.indexOf(itemPass);
      if (index > -1) {
        this.arrDrugsDosage.splice(index, 1);
      }
      this.CheckStep1();
    },
    CheckStep1() {
      //Section I
      let case1 = false;
      let case2 = false;
      if (this.maf_test != null) {
        case1 = true;
      }
      if (this.maf_reason != null) {
        if (this.maf_reason == "4") {
          if (this.maf_reason_remark != "") {
            case2 = true;
          }
        } else {
          case2 = true;
        }
      }
      //Section I

      //Section II
      let case3 = false;
      if (this.maf_consent_permiss != null && this.maf_consent_permiss == 'true') {
        case3 = true;
      } else {
        case3 = false;
      }
      //Section II

      //Section III
      let case4 = false;
      if (this.maf_consent_drug != null) {
        if (this.maf_consent_drug == 'true') {
          if (this.arrDrugsDosage.length > 0) {
            let case11 = true;
            this.arrDrugsDosage.forEach(element => {
              if (element.mafd_drugs_name == "" || element.mafd_dosage == "" || element.mafd_prescribed_by == "") {
                case11 = false;
                return;
              }
            });
            if (case11) {
              case4 = true;
            } else {
              case4 = false;
            }
          }
        } else {
          this.arrDrugsDosage = [];
          case4 = true;
        }
      }
      //Section III

      if (case1 && case2 && case3 && case4) {
        this.step1 = true;
      } else {
        this.step1 = false;
      }
    },

    GotoStepDialog2() {
      this.stepdialog = 2;
      this.GotoFocusStep('focusStep2')
    },
    CheckStep2() {
      //Section IV
      if (this.maf_declare_1 != null && this.maf_declare_2 != null && this.maf_declare_3 != null && this.maf_declare_4 != null && this.maf_declare_5 != null && this.maf_declare_6 != null && this.maf_declare_7 != null && this.maf_declare_8 != null && this.maf_declare_9 != null && this.maf_declare_10 != null)  {
        this.step2 = true;
      } else {
        this.step2 = false;
      }
      //Section IV
    },

    GotoStepDialog3() {
      this.stepdialog = 3;
      this.GotoFocusStep('focusStep3')
    },

    async SaveToDB() {
      try {
        let q = {};
        q.p_id = this.$route.params.id;

        let res = await feathersClientUOA
          .service("mubadala-alcohol-form-2")
          .find({ query: q });

        if (res.total == 1) {
          let maf_id = res.data[0].maf_id;
          let dataUpdate = {
            maf_test: this.maf_test,
            maf_reason: this.maf_reason,
            //#Section IV
            maf_declare_1:this.maf_declare_1,
            maf_declare_2:this.maf_declare_2,
            maf_declare_3:this.maf_declare_3,
            maf_declare_4:this.maf_declare_4,
            maf_declare_5:this.maf_declare_5,
            maf_declare_6:this.maf_declare_6,
            maf_declare_7:this.maf_declare_7,
            maf_declare_8:this.maf_declare_8,
            maf_declare_9:this.maf_declare_9,
            maf_declare_10:this.maf_declare_10,
            //#Section IV
            maf_reason_remark: this.maf_reason_remark,
            maf_consent_permiss: this.maf_consent_permiss,
            maf_consent_drug: this.maf_consent_drug,
            maf_consent: true,
            maf_status: "Wait",
          };
          await feathersClientUOA
            .service("mubadala-alcohol-form-2")
            .patch(maf_id, dataUpdate);

          this.dialogConfirm = false;
          this.dialogSaveComplete = true;

          //#Section III DrugsDosage
          if (this.arrDrugsDosage.length > 0) {
            let i = 0;
            this.arrDrugsDosage.forEach(async (element) => {
              let dataAdd = {
                maf_id: maf_id,
                mafd_drugs_no: (i = i + 1),
                mafd_drugs_name: element.mafd_drugs_name,
                mafd_dosage: element.mafd_dosage,
                mafd_unit: element.mafd_unit.un_name,
                mafd_prescribed_by: element.mafd_prescribed_by,
                mafd_date: element.mafd_date,
              };
              console.log("dataAdd " + JSON.stringify(dataAdd));
              await feathersClientUOA
                .service("mubadala-alcohol-form-detail-2")
                .create(dataAdd);
            });
          }
          //#Section III DrugsDosage
        }
      } catch (e) { }
    },
    GotoFocusStep(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },

    BackStepDialog(step) {
      if (step == 1) {
        this.GotoFocusStep('focusStep1')
      }
      if (step == 2) {
        this.GotoFocusStep('focusStep2')
      }
      if (step == 3) {
        this.GotoFocusStep('focusStep3')
      }
      this.stepdialog = step;
    }
  },
};
</script>
<style lang="scss" scoped>
.box {
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
}

.t-company {
  font-size: 12px;
}
</style>>
